import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
  MailIcon,
  PhoneIcon,
  FacebookIcon,
  InstagramIcon,
  TripAdvisorIcon,
} from "../elements/Icons"

import { useContact } from "../../utils/useContactSection"
import { useLanguageContext } from "../../utils/LanguageContext"

const Contact = () => {
  const data = useContact()
  const { toggle } = useLanguageContext()
  const langFilteredEs = data.filter(i => i.lang === "es")
  const langFilteredEn = data.filter(i => i.lang === "en")

  return (
    <>
      <div className="flex flex-col justify-start lg:hidden px-4">
        <h1 className="h1-mobile">
          {toggle === "es"
            ? langFilteredEs.map(i => i.title)
            : langFilteredEn.map(i => i.title)}
        </h1>
        <div className="my-6">
          <p className="footer-text max-w-sm">
            {toggle === "es"
              ? langFilteredEs.map(i => i.paragraph1)
              : langFilteredEn.map(i => i.paragraph1)}
          </p>
          <p className="footer-text mt-4">
            {" "}
            América Latina 32, San Francisco,
            <br /> Nayarit, México.
          </p>
        </div>
        <div className="flex items-center py-2">
          <PhoneIcon />
          <p>+52 3221044161</p>
        </div>
        <div className="flex items-center py-2">
          <MailIcon />
          <p>elestar.centro@gmail.com</p>
        </div>
        <div className="flex pt-3">
          <a
            href="https://www.facebook.com/elestarsanpancho/
"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/elestaryogasan/
"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.tripadvisor.com.mx/Attraction_Review-g552077-d21334754-Reviews-El_Estar-San_Francisco_Pacific_Coast.html"
            target="_blank"
            rel="noreferrer"
          >
            <TripAdvisorIcon />
          </a>
        </div>
        <a
          href="https://goo.gl/maps/BHrdzfFjdqoBuqKq9"
          target="_blank"
          rel="noreferrer"
          className=""
        >
          <StaticImage
            width={312}
            height={312}
            src="../../images/map.png"
            alt="location map"
            placeholder="blurred"
            className="mt-8 w-3/4"
          />
        </a>
      </div>
      <div className="hidden lg:flex justify-around mx-auto pt-16">
        <div>
          <h1 className="h1-desktop">
            {" "}
            {toggle === "es"
              ? langFilteredEs.map(i => i.title)
              : langFilteredEn.map(i => i.title)}
          </h1>
          <div className="my-6">
            <p className="footer-text max-w-sm">
              {toggle === "es"
                ? langFilteredEs.map(i => i.paragraph1)
                : langFilteredEn.map(i => i.paragraph1)}
            </p>
            <p className="footer-text mt-4">
              {" "}
              América Latina 32, San Francisco,
              <br /> Nayarit, México.
            </p>
          </div>
          <div className="flex items-center py-2">
            <PhoneIcon />
            <p>+52 3221044161</p>
          </div>
          <div className="flex items-center py-2">
            <MailIcon />
            <p>elestar.centro@gmail.com</p>
          </div>
          <div className="flex pt-3">
            <a
              href="https://www.facebook.com/elestarsanpancho/
"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/elestaryogasan/
"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.tripadvisor.com.mx/Attraction_Review-g552077-d21334754-Reviews-El_Estar-San_Francisco_Pacific_Coast.html"
              target="_blank"
              rel="noreferrer"
            >
              <TripAdvisorIcon />
            </a>
          </div>
        </div>
        <a
          href="https://goo.gl/maps/BHrdzfFjdqoBuqKq9"
          target="_blank"
          rel="noreferrer"
          className="w-1/3"
        >
          <StaticImage
            width={474}
            height={474}
            src="../../images/map.png"
            alt="location map"
            placeholder="blurred"
            className=""
          />
        </a>
      </div>
    </>
  )
}

export default Contact
